// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-docs-brazil-companies-js": () => import("./../src/pages/docs/brazil-companies.js" /* webpackChunkName: "component---src-pages-docs-brazil-companies-js" */),
  "component---src-pages-docs-company-reports-js": () => import("./../src/pages/docs/company-reports.js" /* webpackChunkName: "component---src-pages-docs-company-reports-js" */),
  "component---src-pages-docs-insider-js": () => import("./../src/pages/docs/insider.js" /* webpackChunkName: "component---src-pages-docs-insider-js" */),
  "component---src-pages-docs-insider-trading-js": () => import("./../src/pages/docs/insider-trading.js" /* webpackChunkName: "component---src-pages-docs-insider-trading-js" */),
  "component---src-pages-docs-reports-js": () => import("./../src/pages/docs/reports.js" /* webpackChunkName: "component---src-pages-docs-reports-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

